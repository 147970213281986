import { GlobalOptions, authentication } from '@modular-rest/client';
import { useProfileStore } from '~/stores/profile';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const profileStore = useProfileStore();
  const loginRoute = '/auth/login';

  GlobalOptions.set({
    // the base url of the server, it should match with the server address
    host: config.public.BASE_URL_API || window.location.origin,
  });

  addRouteMiddleware('auth', async (to, from) => {
    if (to.path === loginRoute) {
      return navigateTo(to.path);
    } else if (!authentication.isLogin) {
      await authentication.loginWithLastSession().catch(() => null);
    }

    if (!authentication.isLogin) {
      return navigateTo(loginRoute);
    } else {
      await profileStore.bootUserProfile(authentication.user!);
    }

    // if the user is logged in, then we can continue
  });
});

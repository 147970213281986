import { authentication, dataProvider } from '@modular-rest/client';
import { defineStore } from 'pinia';
import type { UserDetail } from '@/types/user.type';
import { DATABASE, PROFILE_COLLECTION } from '~/config';
import type User from '@modular-rest/client/dist/class/user';
import { useInstagramAdapterStore } from './instagram-adapter';
export const useProfileStore = defineStore('profile', () => {
  const authUser = computed(() => authentication.user);
  const isLogin = computed(() => authentication.isLogin);

  const userDetail = ref<UserDetail>();
  const profilePicture = computed(() => userDetail.value?.gPicture || '');
  const email = computed(() => authUser.value?.email);

  function logout() {
    authentication.logout();
    userDetail.value = undefined;
  }

  async function bootUserProfile(user: User) {
    // get profile picture
    const userData = await dataProvider.findOne<UserDetail>({
      database: DATABASE,
      collection: PROFILE_COLLECTION,
      query: {
        refId: user.id,
      },
    });

    if (userData) {
      userDetail.value = userData;
    }

    // fetch platform connections
    await useInstagramAdapterStore().fetchProfiles();

    return user;
  }

  return {
    authUser,
    userDetail,
    isLogin,
    profilePicture,
    email,

    logout,
    bootUserProfile,
  };
});

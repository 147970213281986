import { authentication, dataProvider } from '@modular-rest/client';
import { defineStore } from 'pinia';
import { INSTAGRAM_DATABASE, INSTAGRAM_PROFILE } from '~/config';
import type { InstagramProfileType } from '~/types/instagram.type';

export const useInstagramAdapterStore = defineStore('instagramAdapter', () => {
  const profiles = ref<InstagramProfileType[]>([]);
  // const profiles

  const totalProfiles = computed(() => profiles.value.length);

  const activeProfile = computed(() => profiles.value.find((profile) => profile.isDefault));

  async function fetchProfiles() {
    return dataProvider
      .find<InstagramProfileType>({
        database: INSTAGRAM_DATABASE,
        collection: INSTAGRAM_PROFILE,
        query: { refId: authentication.user?.id },
      })
      .then((list) => {
        profiles.value = list;
      });
  }

  async function switchAccount(_id: string) {
    return Promise.all([
      // Update the current default profile to be not default
      //
      dataProvider.updateOne({
        database: INSTAGRAM_DATABASE,
        collection: INSTAGRAM_PROFILE,
        query: { refId: authentication.user?.id, isDefault: true },
        update: { isDefault: false },
      }),

      // Update the selected profile to be default
      //
      dataProvider.updateOne({
        database: INSTAGRAM_DATABASE,
        collection: INSTAGRAM_PROFILE,
        query: { _id, refId: authentication.user?.id },
        update: { isDefault: true },
      }),
    ]).then(fetchProfiles);
  }

  function addProfileFromJson(profileJson: string) {
    const profile = JSON.parse(profileJson);
    profiles.value.push(profile);
  }

  return {
    profiles,
    totalProfiles,
    activeProfile,

    fetchProfiles,
    addProfileFromJson,
    switchAccount,
  };
});
